import React from 'react'
import { graphql } from 'gatsby'
import { map } from 'lodash'

import Thumbs from '../components/thumbs'
import Layout from '../components/layout'

export default class NotFoundPage extends React.PureComponent {
  render() {
    const galleries = map(this.props.data.galleries.edges, 'node')

    return (
      <Layout>
        <div style={{ textAlign: 'center', padding: '0 20px' }}>
          <h1>Oops, I broke something.</h1>
          <p
            style={{
              fontSize: '16px',
              width: '50%',
              margin: '0 auto',
              marginBottom: '4rem',
            }}
          >
            Sorry about that. Maybe you can find what you're looking for in one
            of the galleries below?
          </p>
          <div style={{ padding: '20px 20px 50px 20px' }}>
            <Thumbs galleries={galleries} />
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query NotFound {
    galleries: allGallery(
      sort: { fields: [name], order: DESC }
      filter: { isPrivate: { eq: false } }
    ) {
      edges {
        node {
          title
          formattedDate
          slug
          poster {
            fixed(width: 300, height: 200, quality: 60, crop: "fill") {
              aspectRatio
              src
              srcSet
              width
              height
            }
            colors {
              light
            }
          }
        }
      }
    }
  }
`
